.table-wrapper {
  .buttons {
    position: fixed;
    bottom: 1em;
    right: 1em;

    button {
      margin-right: 1em;
    }
  }
}

.table-form {
  input, select {
    font-size: 0.9em;
  }

  th {
    cursor: pointer;
    text-align: center;
    background-color: #666;
    color: #fff;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 3;
  }

  td {
    text-align: center;
  }

  th, td {
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }

  th:first-child {
    z-index: 5;
  }

  tr {
    &.modified {
      background-color: orange;
    }
    &.active {
      background-color: #007bff;
    }
    &.archived {
      background-color: red;
    }
  }

  table-layout: fixed;

  textarea {
    font-size: 0.9em;
  }

  .actions {
    white-space: nowrap;
    text-align: right;

    i {
      cursor: pointer;
      font-size: 1.5em;
      color: #666;
      margin: 0 0.5em;
    }
  }
}