.page-content {
  display: flex;
  justify-content: center;
}

.reset-password form {
  min-width: 400px;
}

.reset-password h5 {
  margin: 2em auto;
}

.reset-password button {
  margin: 2em auto;
}