@import './table-form';

table {
  /* table-layout: fixed; */
  /* width: 100%; */
  /* border-collapse: ; */
  /* border: 3px solid purple; */
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

th,
td {
  padding: 2px 5px;
}

tbody tr.active {
  background-color: #DE6B9A;
}

.content {
  /* max-width: 900px; */
  margin: 0 auto;
}

.search-msg {
  padding: 1em;
  background-color: #eee;
  border-radius: 1em;
  margin: 1em;
}

.search-results {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.search-result {
  cursor: pointer;
  opacity: 0.8;
}

.search-result:hover {
  opacity: 1;
}

.form-group {
  display: flex;
  align-items: flex-start;

  .form-group-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
  }
}

.help {
  font-size: 0.8em;
  margin: 0;
  font-style: italic;
}

.form-check {
  margin-left: 200px;
}

input[type=checkbox] {
  height: 2em;
  width: 2em;
}

.form-group label {
  width: 200px;
  min-width: 200px;
  text-align: right;
  padding-right: 1em;
  padding-top: 0.3em;
  font-weight: 600;
}

.login,
.home {
  width: 400px;

  input,
  button {
    margin: 1em 0;
  }
}

.mini-form {
  text-align: center;
  margin: 0 auto;
  margin-top: 3em;
  flex-flow: column;
  align-items: center;
  max-width: 600px;

  img {
    height: 100px;
  }

  form {
    margin: 2em;
  }

  .form-group {
    flex-flow: column;

    label {
      text-align: left;
      font-weight: bold;
      min-width: none;
    }
  }

  .alert {
    text-align: left;
    margin-top: 1em;
  }
}

.company-editor {
  margin: 0 3em;

  .co-search {
    width: 100%;
  }

  .form-fields {
    // display: flex;
    // flex-flow: row wrap;

    display: inline-grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, auto);

    width: 100%;
    // justify-items: center;
    // justify-content: center;

    .form-group {
      width: 500px;
      margin-right: 1em;
    }
  }
}

.buttons {
  margin: 2em 0;

  button {
    margin-right: 1em;
  }
}

.rating-inputs {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 2em;

  input {
    width: 5em;
    font-size: 0.9rem;
  }
}

.rating-group {
  flex: auto;
  padding: 1em;
  margin: 0.5em 0.5em 0;

  .form-group {
    margin: 0;
    text-align: right;

    label {
      width: 150px;
      min-width: 150px;
      color: #aaa;
    }

    .included {
      color: green;
    }

    .fa {
      color: green;
    }

    .scale {
      display: block;
      font-size: 0.8em;
    }

    .abb {
      display: block;
      white-space: nowrap;
      font-size: 0.8em;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }


  .inputs {
    display: inline-grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(4, auto);
    overflow-x: auto;
  }
}

.rating-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    margin: 1em;
    border: 1px solid #ddd;
  }
}

.known-labels {
  margin-left: 200px;
}

.known-labels .no-labels {
  background-color: #eee;
  padding: 1em;
  border-radius: 1em;
}

.known-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5em;
  border: 1px solid #eee;
}

.calculated-ratings {
  background-color: #eee;
  padding: 1em;
  border-radius: 1em;
  flex: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar-nav .nav-item {
  margin: 1em;
}

.transaction .categories {
  color: #666;
  font-size: 0.8em;
}

.unknowns-mgr .mappings-mgr {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 1em;
}

.transactions-list,
.co-moderation {
  .control-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;

    .spinner {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.controls {
  display: flex;
  align-items: flex-end;
  padding-bottom: 1em;

  &>* {
    margin-right: 1em;
  }
}

.transaction {
  .date {
    position: absolute;
    font-size: 0.8em;
    color: #aaa;
    padding: 1em;
    top: 0;
    right: 0;
  }

  .buttons {
    position: absolute;
    margin: 0;
    right: 0;
    bottom: 0;
  }

  .raw-json {
    background-color: #ccc;
    font-size: 0.8em;
  }
}

.transaction .mapping-info {
  background-color: green;

  &.user {
    background-color: #aaa;
  }

  color: white;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  display: inline-block;
}

.transaction div {
  padding: 0.2em;
}

.transactions-list {
  flex: 3;
  text-align: center;
}

.transactions-list .list-group {
  text-align: left;
}

.transactions-layout {
  display: flex;
}

.transactions-layout>* {
  margin-right: 1em;
}

.map-unknown {
  flex: 1;

  .co-search>div {
    position: sticky;
    top: 5em;
  }
}

.co-search {
  position: relative;

  .list-group {
    max-height: 600px;
    overflow-y: scroll;
    border-bottom: 1px solid #ccc;
    position: absolute;
    z-index: 5;
    top: 3em;
    width: 100%;
  }
}


.spinner {
  justify-content: center;
  align-items: center;
  display: flex;

  &.block {
    padding: 2em;

    i {
      font-size: 3rem;
    }
  }

  i {
    animation: spin 1s linear infinite;
  }
}

.label-feedback tr.approve {
  background-color: aquamarine;
}

.label-feedback tr.deny {
  background-color: #ffd1d1;
}

.label-feedback button {
  margin: 0.2em;
}

.company-landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  display: flex;
  align-items: stretch;
  max-width: 500px;
  margin: auto;
  padding-top: 1em;
}

.fold-out-toggle {
  background-color: black;
  padding: 0.3em;
  color: white;
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 0.3em;
  font-weight: bold;
  cursor: pointer;
}

.fold-out-toggle .icon {
  margin-left: 0.5em;
}

.brand-info {
  margin-bottom: 1em;
}

.store-badges {
  text-align: center;
}

.store-badges p {
  text-align: left;
  margin: 0 30px;
}

.store-badges img {
  height: 80px;
}

.ecountabl-nav {
  background-color: #fff;
  font-family: "Fjalla One";
}

.ecountabl-nav .navbar-collapse {
  justify-content: flex-end;
}

footer {
  padding: 1em;
  margin-top: 1.5em;
}

footer p {
  text-align: center;
  margin-bottom: 0;
}

footer.ecountabl-nav {
  flex: 1;
  flex-direction: column;
  align-items: center;
}

footer .ecountabl-nav .navbar-collapse {
  justify-content: center;
}

footer .navbar-nav {
  flex: 1;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

footer .navbar-nav li {
  margin: 0.5em 0.8em;
}

footer .social-icons {
  text-align: center;
  margin: 0.5em;
  font-size: 2em;
}

footer .social-icons svg {
  margin: 0.2em;
}

.footer-addl {
  font-family: 'Roboto';
  font-weight: 300;
}

.ecountabl-nav .navbar-brand img {
  width: 100px;
}

.ecountabl-nav a {
  color: #41464e;
}

@media (max-width: 991px) {
  .ecountabl-nav .navbar-collapse {
    display: none;
  }

  .navbar-toggler {
    font-size: 2em;
    color: white;
  }

  .ecountabl-nav .navbar-toggler {
    display: block;
  }
}

.logo-editor {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  label {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .overlay-controls {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;

      &>* {
        margin: 0.5em;
      }

      .logo-search {
        justify-content: flex-end;
        display: flex;
      }

      .search-message {
        padding: 0.2em;
        border-radius: 0.2em;
        background-color: lightblue;
      }
    }

    .img-default {
      background-color: #eee;
      height: 100%;
      text-align: center;
      flex: 1;
      align-self: stretch;
      font-size: 30px;
      font-weight: bold;
      color: #aaa;
    }
  }

  text-align: center;

  .sizer {
    width: 400px;
    height: 200px;
    border: 2px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  img {
    background-color: #fafafa; // enable visibility of white images
    align-self: center;
    max-width: 100%;
    max-height: 100%;
  }

  .from-api {
    color: red;
  }
}

.logo-editor .spinner {
  height: 200px;
  width: 200px;
  font-size: 7em;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner i {
  animation: spin 1.5s linear infinite;
}

.all-white-disclaimer {
  color: #ccc;
  font-size: 0.8em;
  font-style: italic;
}

.logo-editor img:hover {
  cursor: pointer;
  opacity: 0.6;
}

.score-tinkerer {
  /* padding-top: 4em; */
}

.beta-signup-link {
  color: white;
  padding: 0.5em 1em;
  font-size: 1.2em;
  background-color: black;
  margin: 1em;
  display: inline-block;
  font-weight: bold;
}

.epagination {
  display: flex;
}

.epagination .page-no {
  padding: 0.5em 1em;
}

.questions {
  margin-bottom: 1em;
}

.question {
  // padding: 0.5em;
  border-bottom: 1px solid #eee;
  margin-bottom: 1em;

  .q-label {
    font-size: 2em;
  }
}

.csr-right-panel,
.right-panel {
  flex: 2;
  position: sticky;
  top: 5em;
  align-self: flex-start;

  .form-group {
    flex-direction: column;

    label {
      text-align: left;
    }
  }
}

.csr-cos {
  top: 0;
  z-index: 1;

  .active {
    background-color: #eee;
  }

  .csr-co {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0.8em;
    align-items: center;

    .add-new {}

    i.fa {
      color: green;
      font-size: 1.2em;
    }
  }

  .csr-co:hover {
    background-color: #ddd;
  }
}


.indicators-editor {
  .indicator-warnings {
    background-color: orange;
    margin: 1em;
    padding: 1em;
  }

  .buttons {
    position: fixed;
    bottom: 1em;
    right: 1em;

    button {
      margin-right: 1em;
    }
  }
}

.co-data-uploads {
  .co-data-wrap {
    display: flex;
  }
}

.company-data-uploader {
  .header-mappings {
    margin: 1em 0;

    .header-mapping {
      display: inline-flex;
      align-items: center;
      margin-bottom: 1em;

      .header-name {
        width: 300px;
      }

      &>* {
        margin-right: 0.5em;
      }
    }
  }

  .header-config {
    .form-group {
      align-items: flex-end;

      button {
        margin-left: 1em;
      }
    }
  }

  .co-upload-matches {
    .match-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .match-buttons {
      text-align: right;

      i {
        background-color: #ccc;
        font-size: 1.2em;
        padding: 0.3em;
        cursor: pointer;
        margin-left: 0.2em;
        border-radius: 0.3em;
      }
    }

    .reviewed {
      .match-name {
        outline: 1px solid orange;
      }
    }
  }
}

.tag-input {
  flex: 1;

  .input-wrap {
    position: relative;
  }

  .tags {
    margin-bottom: 1em;
  }

  .tag {
    background-color: #ccc;
    border-radius: 0.3em;
    display: inline-block;
    padding: 0.5em;
    font-size: 0.8em;
    margin: 0 0.5em 0.5em 0;
    white-space: nowrap;

    &.new-tag {
      padding: 0;
      border: 1px solid red;

      .tag-text {
        padding: 0.5em;
      }

      .new-label {
        text-align: center;
        display: block;
        background-color: red;
        color: white;
      }
    }

    .fa {
      padding: 0.5em;
      cursor: pointer;
    }
  }

  .tag-options {
    position: absolute;
    top: 2.5em;
    max-height: 20em;
    overflow: scroll;
    z-index: 10;

    .list-group-item {
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }
  }

  input {
    display: block;
  }
}

.corp-children {
  margin-bottom: 1em;

  a {
    margin-right: 1em;
  }
}

.user-home {
  .api-keys {
    max-width: 600px;

    .card {
      margin-bottom: 1em;

      .api-key-meta {
        display: flex;
        justify-content: space-between;
      }

      .api-stats {
        margin-top: 0.6em;
        padding-top: 0.6em;
        font-size: 0.9em;
        border-top: 1px solid #ccc;

        li {
          list-style: none;
          display: flex;
          justify-content: space-between;
          padding-right: 5em;
        }
      }
    }
  }
}

.campaigns {
  display: flex;
  flex-flow: row wrap;

  .campaign {
    border: 1px solid #ccc;
    cursor: pointer;
    margin: 0 1em 1em 0;
    display: flex;
    align-items: center;
    width: 250px;
    padding: 0.5em;
    border-radius: 0.3em;

    .details {
      font-size: 0.8em;
      margin-left: 1em;
    }

    &.active {
      border: 2px solid green;
    }

    &.selected {
      border: 4px solid lightblue;
    }

    img {
      width: 80px;
      height: fit-content;
    }

    .status {
      display: inline-block;
      color: white;

      &.active {
        margin: 0.2em 0;
        padding: 0.2em 0.4em;
        border-radius: 1em;
        background-color: green;
      }

      &.inactive {
        color: #aaa;
      }
    }
  }
}

.current-campaign {
  .deal {
    .deal-name {
      font-weight: bold;
    }

    .deal-details {
      font-size: 0.8em;
    }
  }

  margin: 1em auto;
}

.v-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &>button {
    margin-bottom: 0.5em;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;

  .overlay-close {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    padding: 0.5em;
    cursor: pointer;

    i {
      font-size: 2em;
    }
  }

  .overlay-cont {
    background-color: white;
    border-radius: 0.5em;
    position: relative;
    padding: 1em;
  }
}

.detail-editor {
  max-width: 1024px;

  .form-group {
    flex-direction: column;

    label {
      text-align: left;
    }
  }

  .faq-config {
    display: flex;

    &>* {
      flex: 1;
    }
  }

  .faq-ex {
    font-style: italic;
    font-size: 0.9em;
    margin: 0;
  }
}

.error {
  color: red;
}

.data-page {
  .data-form {
    margin: 1em 1em 2em;
  }

  .data-form-buttons {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.company-search-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-page {
  margin: 3em;
}

.select-multi {
  .select-multi-panel {
    flex: 1 0;
  }
}

.impact-area-form {
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 0.5em;
  padding: 0.5em;
  margin-bottom: 1em;
  background-color: #efefef;

  .form-wrap {
    display: flex;
    flex-wrap: wrap;
    flex: 10;
    justify-content: space-between;

    .form-group {
      flex-grow: 10;
    }
  }

  &>div {
    flex: 1;
    margin-left: 0.5em;
  }

  .form-group {
    margin: 0.5em 0.5em 0;
  }

  .ic-wrap {
    min-width: 0;
  }
}

.month-select {
  width: 200px;

  .mo {
    width: 42px;
    text-align: center;
    margin: 0.3em;
    display: inline-block;
    align-items: center;
    padding: 0.4em;
    background-color: #eee;
    border-radius: 0.2em;
    font-size: 0.9em;

    &.selected {
      background-color: cornflowerblue;
      color: white;
    }
  }
}

.btn-normal {
  background-color: cadetblue;
  color: white;
}

.bank-indicator-row {
  input.form-control {
    margin-right: 1em;
    width: 150px;
    text-align: right;
  }
}

.form-control::placeholder {
  opacity: 0.4;
}

.data-form {
  display: inline-block;
}

.idf-hdr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.idf-col-hdr {
  display: flex;
  flex-flow: row-reverse;
  align-items: end;

  div {
    padding: 0.5em;
    width: 150px;
    text-align: center;
    font-weight: bold;
    margin-right: 1em;
  }
}

.grid {
  display: grid;

  .grid-hdr {
    display: flex;
    justify-content: space-around;
    font-weight: bold;

    &>div {
      text-align: center;
    }
  }
}

.icon-chooser {
  position: relative;

  .form-group {
    display: flex;
    flex-direction: row;

    button {
      margin-left: 0.5em;
      white-space: nowrap;
    }
  }

  .icon-options {
    display: flex;
    flex-wrap: wrap;
    max-height: 400px;
    overflow-y: scroll;
    padding: 0.5em;
  }

  .icon-label {
    white-space: nowrap;
  }

  .icon-opt {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6em;
    font-size: 0.8em;
    margin: 0.2em;
    padding: 0.2em;
    cursor: pointer;

    &:hover {
      // color: ;
      background-color: #ccc;
    }
  }

  .trigger {
    cursor: pointer;
    height: 4em;
    width: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #aaa;
    vertical-align: center;

    span {
      padding: 0.2em;
      font-size: 0.8em;
    }
  }

  .popup {
    position: absolute;
    top: 100%;
    width: 400px;
    padding: 0.5em;
    border: 1px solid #ccc;
    z-index: 10;
    background-color: #fff;
  }
}

.values-sort-order {
  .list-group-item {
    display: flex;
    justify-content: space-between;

    .fa {
      padding: 0 0.5em;
      cursor: pointer;
      font-size: 1.5em;
    }
  }
}

.integrations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1em;

  .finastra-form {
    min-width: 400px;
    margin: 0 1em 1em 0;
    padding: 1em;
    background-color: #eee;
    border-radius: 0.5em;

    .form-group {
      flex-direction: column;
      margin-bottom: 0.5rem;
    }

    label {
      text-align: left;
    }
  }
}

.central-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: white;
    padding: 2em;
    border-radius: 0.5em;
    max-width: 800px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
  }
}

.mapping-control-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em;
  padding: 1em 0;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;

  .mapping-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    label {
      margin: 0;
    }
  }


}

.mapping-item {
  display: flex;
  flex-direction: row;
  padding: 1em;
  border: 1px solid #ccc;
  margin: 0 1em 1em 1em;
  border-radius: 8px;
  cursor: pointer;

  .texts {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mapping-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2em;
  }
}

.selected-mapping{
  background-color: #eee;
}

.mapping-mgr-body {
  display: flex;
  gap: 1em;

  .col {
    display: flex;
    flex-direction: column;
  }

  .primary {
    flex: 1;
    min-width: 600px;
  }
  .secondary {
    flex: 0.2;
    min-width: 300px;
    height: "100%";
  }

  .transactions-column {
    position: sticky;
    top: 2em;
    height: 80vh;

    .items {
      overflow-y: auto;
      max-height: 80vh;
    }
  }
}
.custom-text-field-section {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .custom-text-field-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }
}