.users-mgr {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 1em;
}

.user-mgr-body {
  display: flex;
  gap: 1em;
  flex-direction: column;

  .col {
    display: flex;
    flex-direction: column;
  }

  .primary {
    flex: 1;
    min-width: 600px;
  }
  .secondary {
    flex: 0.2;
    min-width: 300px;
    height: "100%";
  }

  .transactions-column {
    position: sticky;
    top: 2em;
    height: 80vh;

    .items {
      overflow-y: auto;
      max-height: 80vh;
    }
  }
}

.user-control-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em;
  padding: 1em 0;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  gap: 1em;

  .user-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    label {
      margin: 0;
    }
  }

  .max-width {
    width: 100%;
  }
}

.user-item {
  display: flex;
  flex-direction: column;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

  .main-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;

    .item {
      display: flex;
      flex: 1;
      max-width: 30%;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    .item-sm {
      display: flex;
      flex: 0.25;
      max-width: 20%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-wrap: none;
    }
  }

  .interactive {
    cursor: pointer;
  }

  .expanded-section {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    padding: 1em;
    border-top: 1px solid #ccc;
    margin-top: 1em;

    .user-detail-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1em;

      .detail {
        display: flex;
        flex-direction: row;
        gap: 0.25em;
        flex: 1;
      }

      .label {
        font-weight: bold;
      }

      .value {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .account {
        border: 1px solid black;
        border-radius: 1em;
        padding: 1em;
        background-color: white;
      }

      .valueItem {
        display: flex;
        flex-direction: row;
        flex: 1;
      }
    }

    .user-actions-row {
      display: flex;
      flex-direction: row;
      gap: 1em;
      justify-content: flex-end;
    }
  }

}